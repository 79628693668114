.navbar {
	background-color: #fff;
	box-shadow: 0px 1px 3px #ddd;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	position: sticky;
	top: 0;
	z-index: 20;
}

.nav-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 60px;
	max-width: 1250px;
}

.nav-logo {
	color: #000;
	align-items: center;
	margin-left: 20px;
	cursor: pointer;
	text-decoration: none;
	font-size: 1.5rem;
	font-weight: bold;
	flex-grow: 1;
}

.nav-menu {
	background-color: #fff;
	display: flex;
	list-style: none;
	text-align: center;
	margin-right: 2rem;
	height: 60px;
}

.nav-links {
	color: #000;
	font-weight: bold;
	text-decoration: none;
	padding: 0.5rem 1rem;
	height: 40px;
	border-bottom: 3px solid transparent;
}

.nav-links > div {
	height: 40px;
}

.fa-code {
	margin-left: 1rem;
}

.nav-item {
	line-height: 60px;
	margin-right: 1rem;
}

.nav-item:after {
	content: "";
	display: block;
	height: 3px;
	width: 0;
	background: transparent;
	transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-icon {
	display: none;
}

.margin-adjust {
	margin-top: -10px;
}

@media screen and (max-width: 960px) {
	.nav-menu {
		height: auto;
		display: flex;
		flex-direction: column;
		width: 100%;
		position: absolute;
		top: 60px;
		left: -110%;
		opacity: 1;
		transition: all 0.5s ease;
	}

	.nav-menu.active {
		background: #fff;
		left: 0px;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 1;
		box-shadow: 0px 1px 3px #ddd;
	}

	.nav-links {
		padding: 1rem;
		width: 100%;
		display: table;
	}

	.nav-icon {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 30%);
		font-size: 1.8rem;
		cursor: pointer;
		color: #000;
	}
}
