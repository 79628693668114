.dx-scheduler-appointment-title {
	text-align: left !important;
}

.dx-toolbar-center {
	padding: 9px;
}

@media only screen and (max-width: 370px) {
	/* .dx-scrollview-content {
		height: 50%;
	} */

	div.dx-scheduler-overlay-panel.dx-overlay-content {
		height: 600px !important;
	}

	.dx-popup:not(.dx-scheduler-appointment-popup)
		.dx-dialog
		.dx-popup-bottom.dx-toolbar
		.dx-toolbar-items-container {
		height: auto;
	}

	.dx-popup:not(.dx-scheduler-appointment-popup)
		.dx-dialog
		.dx-toolbar-items-container
		.dx-toolbar-center {
		display: flex;
		flex-direction: column;
	}

	.dx-popup:not(.dx-scheduler-appointment-popup)
		.dx-dialog
		.dx-toolbar-items-container
		.dx-toolbar-center
		.dx-toolbar-button {
		padding: 0;
	}

	.dx-popup:not(.dx-scheduler-appointment-popup)
		.dx-dialog
		.dx-toolbar-items-container
		.dx-toolbar-center
		.dx-toolbar-button
		.dx-button {
		width: 200px;
	}

	.dx-popup:not(.dx-scheduler-appointment-popup)
		.dx-dialog
		.dx-toolbar-items-container
		.dx-toolbar-center
		.dx-toolbar-button:nth-child(1) {
		margin-bottom: 12px;
	}
}
