* {
	margin: 0;
	padding: 0;
}

.pages {
	text-align: center;
	font-size: 1.5rem;
	/* margin-top: 10%; */
}

.container {
	max-width: 1250px;
	margin: auto;
	height: calc(100vh - 60px);
}

.cursor_pointer {
	cursor: pointer;
}
